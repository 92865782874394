import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import logoImage from "../../../src/assets/img/logo/logo.png";
import $ from "jquery";
import {
  handleClickBurgerIconMobile,
  handleClickScroll,
} from "../../lib/helpers";
import { useTranslation } from "react-i18next";

const HeaderOne = () => {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState("en");

  function changeLanguage(e) {
    setLanguage(e);
    i18n.changeLanguage(e);
  }

  // sticky nav bar
  const [stickyClass, setStickyClass] = useState({
    fixed: "",
    header: "",
  });

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight < 245
        ? setStickyClass({ fixed: "", header: "" })
        : setStickyClass({ fixed: "active-height", header: "sticky-menu" });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  // mobile menu
  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    // if ($(".mobile-menu").length) {
    //   let mobileMenuContent = $(".menu-area .main-menu").html();
    //   $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

    //   //Dropdown Button
    //   $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
    //     "click",
    //     function () {
    //       $(this).toggleClass("open");
    //       $(this).prev("ul").slideToggle(500);
    //     }
    //   );
    //   //Menu Toggle Btn
    //   $(".mobile-nav-toggler").on("click", function () {
    //     $("body").addClass("mobile-menu-visible");
    //   });

    //   //Menu Toggle Btn
    //   $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
    //     $("body").removeClass("mobile-menu-visible");
    //   });
    // }
  }, []);

  // active link switching
  const { hash } = useLocation();
  const isActiveLink = (id) => {
    return id === hash ? "active" : "";
  };

  return (
    <header id="header">
      <div id="header-fixed-height" className={cn(stickyClass.fixed)} />

      <div id="sticky-header" className={cn("menu-area", stickyClass.header)}>
        <div className={cn("container custom-container")}>
          <div className="row">
            <div className="col-12">
              <div
                className={"mobile-nav-toggler"}
                onClick={() => handleClickBurgerIconMobile(true)}
              >
                <i className="fas fa-bars" />
              </div>

              <div className="menu-wrap">
                <nav className={"menu-nav"}>
                  <div className="logo">
                    <Link to={"/"}>
                      <img
                        src={logoImage}
                        alt="BigTech Logo"
                        style={{ height: 83 }}
                      />
                    </Link>
                  </div>

                  <div className={cn("navbar-wrap main-menu d-none d-lg-flex")}>
                    <ul className={"navigation"}>
                      <li
                        className={cn(
                          hash === "" && "active",
                          "menu-item-has-children"
                        )}
                      >
                        <Link
                          to="/"
                          className={"section-link"}
                          onClick={() => handleClickScroll("header")}
                        >
                          {t("home")}
                        </Link>
                      </li>
                      <li className={isActiveLink("#about")}>
                        <Link
                          to="#about"
                          className={"section-link"}
                          onClick={() => handleClickScroll("about")}
                        >
                          {t("about_us")}
                        </Link>
                      </li>
                      <li className={isActiveLink("#our-service")}>
                        <Link
                          to="#our-service"
                          className={"section-link"}
                          onClick={() => handleClickScroll("our-service")}
                        >
                          {t("our_service")}
                        </Link>
                      </li>
                      {/* <li className={isActiveLink("#our-team")}>
                        <Link
                          to="#our-team"
                          className={"section-link"}
                          onClick={() => handleClickScroll("our-team")}
                        >
                          {t("our_team")}
                        </Link>
                      </li> */}
                      <li className={isActiveLink("#contact")}>
                        <Link
                          to="#contact"
                          className={"section-link"}
                          onClick={() => handleClickScroll("contact")}
                        >
                          {t("how_to_order")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className={cn("header-action", "d-none d-md-block")}>
                    <ul>
                      <li className={"header-lang"}>
                        <span className={"selected-lang"}>
                          {language === "en" && "ENG"}
                          {language === "id" && "IDN"}
                          &nbsp;
                          <img
                            src={`/assets/flag/${
                              language === "en" ? "united_states" : "indonesia"
                            }.png`}
                            alt=""
                            style={{ width: 24 }}
                          />
                        </span>
                        <ul className={"lang-list"}>
                          <li>
                            <Link
                              to="#"
                              onClick={() => changeLanguage("id")}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              IDN{" "}
                              <img
                                src="/assets/flag/indonesia.png"
                                alt=""
                                style={{ width: 24 }}
                              />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              onClick={() => changeLanguage("en")}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              ENG{" "}
                              <img
                                src="/assets/flag/united_states.png"
                                alt=""
                                style={{ width: 24 }}
                              />
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className={"header-btn"}>
                        <Link
                          to="#contact"
                          onClick={() => handleClickScroll("contact")}
                          className="btn"
                        >
                          {t("contact_us")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              {/* <!-- Mobile Menu  --> */}
              <div className={`mobile-menu`}>
                <nav className={"menu-box"}>
                  <div
                    className={"close-btn"}
                    onClick={() => handleClickBurgerIconMobile(false)}
                  >
                    <i className="fas fa-times"></i>
                  </div>
                  <div className={"nav-logo"}>
                    <Link to="/">
                      <img src={logoImage} alt="BigTech Logo" title="" />
                    </Link>
                  </div>

                  <div className={"menu-outer"}>
                    {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->  */}
                    <ul className={"navigation"}>
                      <li
                        className={cn(
                          hash === "" && "active",
                          "menu-item-has-children"
                        )}
                      >
                        <Link
                          to="/"
                          className={"section-link"}
                          onClick={() => handleClickScroll("header")}
                        >
                          {t("home")}
                        </Link>
                      </li>
                      <li className={cn(hash === "#about" && "active")}>
                        <Link
                          to="#about"
                          className={"section-link"}
                          onClick={() => handleClickScroll("about")}
                        >
                          {t("about_us")}
                        </Link>
                      </li>
                      <li className={isActiveLink("#our-service")}>
                        <Link
                          to="#our-service"
                          className={"section-link"}
                          onClick={() => handleClickScroll("our-service")}
                        >
                          {t("our_service")}
                        </Link>
                      </li>
                      {/* <li className={isActiveLink("#our-team")}>
                        <Link
                          to="#our-team"
                          className={"section-link"}
                          onClick={() => handleClickScroll("our-team")}
                        >
                          {t("our_team")}
                        </Link>
                      </li> */}
                      <li className={isActiveLink("#contact")}>
                        <Link
                          to="#contact"
                          className={"section-link"}
                          onClick={() => handleClickScroll("contact")}
                        >
                          {t("how_to_order")}
                        </Link>
                      </li>
                    </ul>
                  </div>

                  {/* <div className={"social-links"}>
                    <ul className="clearfix">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </nav>
              </div>

              <div className={"menu-backdrop"} />
              {/* <!-- End Mobile Menu --> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderOne;
